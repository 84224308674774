"use client";

import { useEffect } from 'react';
import { errorHandler } from '@/config/errorReporting';

interface ErrorProps {
  error: Error;
}

export default function Error({ error }: ErrorProps) {
  const reset = () => {
    location.reload();
  }
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
    errorHandler?.report(`Error general de la app: ${error}`)
  }, [error]);

  return (
    <section className='mt-4 w-full flex justify-center items-center gap-8 '>
      <p className='text-center'>Algo salió mal y no se pudo cargar la página</p>
      <button className='button w-52' onClick={reset}>Reintentar</button>
    </section>
  );
}